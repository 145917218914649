import { useChatContext } from './ChatContext'

import { Box, Container, styled } from '@mui/material'
import { ThreadView } from './ThreadView'
import { PromptForm } from './PromptForm'
import { SamplePrompts } from './SamplePrompts'
import { useEffect, useState } from 'react'
import type { MessageThread } from '../model'
import { useFirebaseAuth } from '@progos/firebase-chat'


export function ChatMessages() {
  const { mode, chatManager } = useChatContext()
  if (mode==='view') {
    return null
  }

  if (chatManager.chat===null) {
    return (
      <MessagesContainer>
        <SamplePrompts />
      </MessagesContainer>
    )
  }
  return (
    <MessagesContainer>
      <Messages key={chatManager.id ?? 'new'} />
    </MessagesContainer>
  )
}

export function ChatPrompt() {
  const { mode, chatManager } = useChatContext()
  const { user } = useFirebaseAuth()
  const { chat } = chatManager

  const otherUser = chat!=null && (user==null || chat.created_by!==user.uid)

  const readonly = mode==='view' || otherUser
  if (readonly) {
    return null
  }

  return (
    <PromptFormContainer>
      <PromptForm />
    </PromptFormContainer>
  )
}

export function Messages() {
  const chatContext = useChatContext()

  const { chatManager } = chatContext

  const [thread, setThread] = useState<MessageThread>(chatManager.thread)
  useEffect(() => {
    if (chatManager!==undefined) {
      chatManager.addEventListener('threadUpdate', setThread)
      return () => {
        chatManager.removeEventListener('threadUpdate', setThread)
      }
    }
  }, [chatManager])


  return <>
    {thread.map((node, i) => {
      const activeChild = i < thread.length - 1 ? thread[i + 1]:null
      return <ThreadView
          key={node?.message.id ?? chatManager.id}
          tree={node}
          activeChild={activeChild}
        />
    })}
  </>
}

const MessagesContainer = styled(Box, {
  name: 'MessagesContainer',
  slot: 'Root'
})(({ theme }) => ({
  overflowY: 'auto', // Become scrollable vertically
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4), // Space between messages,
  flexGrow: 1
}))

const ChatContainer = styled(Container, {
  name: 'ChatContainer',
  slot: 'Root'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  overflow: 'hidden',
  gap: theme.spacing(2),
  flexGrow: 1
}))

const PromptFormContainer = styled(Box, { name: 'UserMessage', slot: 'Root' })(
  ({ theme }) => ({
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  })
)
