import { useFirestore } from '@progos/firebase-chat'
import { doc } from 'firebase/firestore'
import { courseConverter } from '@goschool/model'
import { useMemo } from 'react'


export interface CourseParams {
  organizationId: string;
  courseId: string;
}

export function useCourseRef({organizationId, courseId}: Readonly<Partial<CourseParams>>) {
  const firestore = useFirestore()

  return useMemo(
    () => {
      if (organizationId!=null && courseId!=null) {
        return doc(firestore,
          'organizations', organizationId,
          'courses', courseId
        ).withConverter(courseConverter)
      }
    }, [organizationId, courseId, firestore]
  )
}


