import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { Course, GoSchoolUserRole } from '@goschool/model'
import type { MenuItemProps } from '@mui/material'
import { CardHeader, CardMedia, Skeleton } from '@mui/material'

import {
  Card, CardActions, IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
  styled
} from '@mui/material'
import { GoSchool } from '@goschool/routing'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import { useUserContext } from '@goschool/auth'
import { useCallback, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { LinkInvitationDialog, QRInvitationDialog } from './CourseInvitation'
import LinkIcon from '@mui/icons-material/Link'
import EditIcon from '@mui/icons-material/Edit'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import { EditCourseDialog } from './EditCourseDialog'
import type { SvgIconComponent } from '@mui/icons-material'
import { Trans } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

interface CourseCardProps {
  courseSnapshot: QueryDocumentSnapshot<Course>,
  course: Course
}

export function CourseCard({ courseSnapshot, course }: CourseCardProps) {
  const { roles } = useUserContext()

  return <StyledCourseCard>
    <CardHeader
      title={course.title}
      subheader={course.code}
      action={<CourseMenu courseSnapshot={courseSnapshot} course={course} />}
    />
    <CourseCardMedia course={course} />
    {/*<CardContent>*/}
    {/*  <Typography*/}
    {/*    variant="body2">{course.description}</Typography>*/}
    {/*</CardContent>*/}
    <CardActions disableSpacing>
      <IconButton size="small" component={RouterLink} to={GoSchool.courseChat(courseSnapshot.ref)}><ForumOutlinedIcon
        fontSize="small" /></IconButton>
      {(roles ?? []).includes('instructor') && <>
        <IconButton size="small" component={RouterLink}
                    to={GoSchool.course(courseSnapshot.ref)}><LibraryBooksOutlinedIcon fontSize="small" /></IconButton>
        <IconButton size="small" component={RouterLink}
                    to={GoSchool.courseStudents(courseSnapshot.ref)}><GroupOutlinedIcon
          fontSize="small" /></IconButton>
      </>}
    </CardActions>
  </StyledCourseCard>
}

function CourseCardMedia({ course }: { course: Course }) {
  if (course.banner!=null) {
    return <CardMedia height={120} component="img" image={course.banner} />
  } else {
    return <Skeleton variant="rectangular" height={120} animation={false} />
  }
}


function CourseMenu({ courseSnapshot }: CourseCardProps) {
  const { roles } = useUserContext()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const open = Boolean(anchorEl)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }, []
  )
  const handleClose = useCallback(
    () => {
      setAnchorEl(undefined)
    }, []
  )

  const allowedRoles: GoSchoolUserRole[] = ['admin', 'instructor']
  if (!allowedRoles.some(role => roles.includes(role))) {
    return null
  }

  return <>
    <IconButton onClick={handleClick}><MoreVertIcon /></IconButton>
    <Menu
      anchorEl={anchorEl} open={open} onClose={handleClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{ paper: { style: { width: '23ch', opacity: 0.95 } } }}>
      <EditItem snapshot={courseSnapshot} />
      {/*<InviteItem />*/}
      <QRInviteItem snapshot={courseSnapshot} />
      <LinkInviteItem snapshot={courseSnapshot} />
    </Menu>

  </>
}

function QRInviteItem({ snapshot }: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [inviting, setInviting] = useState(false)

  return <>
    <CourseMenuItem
      onClick={() => setInviting(true)}
      actionLabel="inviteQR" Icon={QrCode2Icon} />
    <QRInvitationDialog course={snapshot}
                        displayed={inviting} hide={() => setInviting(false)} />
  </>
}

function LinkInviteItem({ snapshot }: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [inviting, setInviting] = useState(false)

  return <>
    <CourseMenuItem
      onClick={() => setInviting(true)}
      actionLabel="inviteLink" Icon={LinkIcon} />
    <LinkInvitationDialog course={snapshot}
                          displayed={inviting} hide={() => setInviting(false)} />
  </>
}

function EditItem({ snapshot }: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [editing, setEditing] = useState(false)

  return <>
    <CourseMenuItem
      onClick={() => setEditing(true)}
      actionLabel="edit" Icon={EditIcon} />
    <EditCourseDialog courseSnapshot={snapshot}
                      displayed={editing} hide={() => setEditing(false)} />

  </>
}

function CourseMenuItem({ onClick, actionLabel, Icon }: {
  onClick: MenuItemProps['onClick'],
  actionLabel: string,
  Icon: SvgIconComponent
}) {
  return <MenuItem onClick={onClick}>
    <ListItemIcon>
      <Icon fontSize="small" />
    </ListItemIcon>
    <ListItemText><Trans i18nKey={`course:actions.${actionLabel}`} /></ListItemText>
  </MenuItem>
}

const StyledCourseCard = styled(Card, { name: 'StyledCourseCard', slot: 'Root' })(
  ({ theme }) => ({
    '& .MuiCardHeader-root': {
      overflow: 'hidden',
      '& .MuiCardHeader-content': {
        overflow: 'hidden',
        '& .MuiCardHeader-title': {
          fontSize: theme.typography.h6.fontSize,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '& .MuiCardHeader-subheader': {
          fontSize: theme.typography.caption.fontSize,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    '& .MuiCardContent-root': {
      height: theme.spacing(8),
      overflow: 'hidden',
      '& .MuiTypography-root': {
        overflowY: 'scroll',
        textOverflow: 'ellipsis',
        maxHeight: '100%'
      }
    },
    '& .MuiCardActions-root': {
      overflow: 'hidden'
    }
  })
)
