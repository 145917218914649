import { Typography, Grid2 as Grid } from '@mui/material'
import { useMemo } from 'react'
import type { DocumentReference } from 'firebase/firestore'
import type { Course } from '@goschool/model'
import { useFirestoreSnapshot } from '@progos/firebase-chat'
import { CourseCard } from './CourseCard'

export function CoursesGrid({ courseRefs }: { courseRefs: DocumentReference<Course>[] }) {
  if (courseRefs.length===0) {
    return <Typography variant="body1">No courses, create one</Typography>
  }

  return <Grid container spacing={4} rowSpacing={4}>
    {courseRefs.map(
      (courseRef, index) =>
        <CourseElement key={index} courseRef={courseRef} />
    )}</Grid>
}


function CourseElement({ courseRef }: { courseRef: DocumentReference<Course> | undefined }) {
  const snapshot = useFirestoreSnapshot(courseRef)

  const course = useMemo(() => snapshot?.data(), [snapshot])
  if (!snapshot?.exists() || course==null) {
    return null
  }
  return <Grid size={{ xs: 12, md: 4, sm: 6 }}>
    <CourseCard courseSnapshot={snapshot} course={course} />
  </Grid>
}
