export const privacyStatement = `
# Privacy Statement

## 1. Data Controller

The data controller is GoSchool-AI Kft. (registered address: 1121 Budapest, Ordas köz 2.), the operator of the [goschool.ai](https://goschool.ai) and [tilosazai.org](https://tilosazai.org) platforms.

## 2. Legal Basis for Data Processing

The legal basis for processing your data is your consent, which you provide by using our services, as well as our legitimate interest in providing and improving our services.

## 3. Purpose of Data Processing

We use your data for the following purposes:
- To provide and improve our services.
- To conduct research and analysis.
- To enhance the user experience.

## 4. Data Processing Methods

Data is automatically collected and stored during the use of our services. The collected data is processed using OpenAI's services, which utilize large language models to support educational processes.

## 5. Data Transfer and Processing

Your data is stored on Google's European servers and processed using OpenAI's services. Data is transferred only to these partners, who guarantee the security and protection of the data.

## 6. Data Retention Period

Data is stored as long as your account remains active or until you request its deletion. Data associated with inactive accounts will be deleted within the legally required period.

## 7. Rights of Data Subjects

You have the right to access your personal data, request its correction, deletion, or restriction of processing. You also have the right to object to the processing and to request data portability.

## 8. Data Security

GoSchool-AI Kft. has implemented appropriate technical and organizational measures to protect the data, including encryption and security protocols.

## 9. Contact for Data Protection Issues

If you have any questions regarding data processing, please contact us via one of the following methods:

- **Email**: info@goschool.ai
- **Postal Address**: GoSchool-AI Kft., 1121 Budapest, Ordas köz 2.

Thank you for trusting us and using our services!

Effective: 30 August 2024
`
