import { Outlet, Route, Routes } from 'react-router-dom'
import { Routing } from '@goschool/routing'
import { App } from './app'
import { InvitationPage, InvitationsPage } from '@goschool/invite'
import { AdminPage, CoursePage, CoursesPage, CourseStudentsPage } from '@goschool/courses'
import { CourseDocumentPage } from '@goschool/courses'
import { CourseChatPage } from '@goschool/chats'
import { NotFound } from './404'
import { TermsOfUse, PrivacyStatement } from '@goschool/site'
import { SentryReportingErrorBoundary } from './SentryReportingErrorBoundary'


function ErrorBoundaryLayout() {
  return <SentryReportingErrorBoundary>
    <Outlet />
  </SentryReportingErrorBoundary>
}

export function GoSchoolRoutes() {
  return <SentryReportingErrorBoundary>
    <Routes>
      <Route index={true} element={<App />} />
      <Route path={Routing.admin} element={<AdminPage />} />
      <Route path={Routing.termsOfUse} element={<TermsOfUse />} />
      <Route path={Routing.privacyStatement} element={<PrivacyStatement />} />
      <Route path={Routing.invitations} element={<InvitationsPage />} />
      <Route path={Routing.invitation} element={<InvitationPage />} />
      <Route path={Routing.courses} element={<CoursesPage />} />
      <Route path={Routing.course} element={<CoursePage />} />
      <Route path={Routing.document} element={<CourseDocumentPage />} />
      <Route path={Routing.courseChat} element={<CourseChatPage />} />
      <Route path={Routing.courseStudents} element={<CourseStudentsPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </SentryReportingErrorBoundary>
}
