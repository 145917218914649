import { Typography } from '@mui/material'
import { Breadcrumb, FixedFab, LoadingPage, PageLayout } from '@goschool/site'
import { Trans } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { CoursesGrid } from './components/CoursesGrid'
import { useMemo } from 'react'
import { useState } from 'react'
import { useUserContext } from '@goschool/auth'
import { CreateCourseDialog } from './components/CreateCourseDialog'

import { GoSchool } from '@goschool/routing'
import { typeConverter, useFirestore, useFirestoreResults } from '@progos/firebase-chat'
import { collectionGroup } from 'firebase/firestore'
import type { Course } from '@goschool/model'

export function AdminBreadcrumb() {
  return <Breadcrumb to={GoSchool.admin}>
    <Trans i18nKey="course:catalog.title" />
  </Breadcrumb>
}

export function AdminPage() {
  const { roles, user, goSchoolUser, state } = useUserContext()
  const [creating, setCreating] = useState(false)

  const firestore = useFirestore()
  const coursesRef = useMemo(
    () => collectionGroup(firestore, 'courses').withConverter(typeConverter<Course>()),
    [firestore]
  )

  const courses = useFirestoreResults(coursesRef)


  if (user===undefined || state==='pending' || goSchoolUser===undefined) {
    return <LoadingPage />
  }


  return <PageLayout fullScreen={false} breadcrumbs={[]}>
      <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:catalog.title" /></Typography>
      <CoursesGrid courseRefs={(courses??[]).map(c => c.ref)} />
      {roles.includes('instructor') &&
        <FixedFab onClick={() => setCreating(true)} color="primary" horizontal="right" vertical="bottom">
          <AddIcon />
        </FixedFab>
      }
    <CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />
  </PageLayout>
}



