export const privacyStatement = `
# Adatvédelmi Nyilatkozat

## 1. Az adatkezelő személye

Az adatkezelő a GoSchool-AI Kft. (székhely: 1121 Budapest, Ordas köz 2.), amely a [goschool.ai](https://goschool.ai) és [tilosazai.org](https://tilosazai.org) platformok üzemeltetője.

## 2. Az adatkezelés jogalapja

Az adatkezelés jogalapja az Ön hozzájárulása, amelyet a szolgáltatásaink használatával ad meg, valamint jogos érdekünk, amely a szolgáltatásaink nyújtásához és fejlesztéséhez kapcsolódik.

## 3. Az adatkezelés célja

Az Ön adatait az alábbi célokra használjuk fel:
- A szolgáltatásaink biztosítása és fejlesztése.
- Kutatási célú elemzések végzése.
- A felhasználói élmény javítása.

## 4. Az adatkezelés módja

Az adatokat automatikusan gyűjtjük és tároljuk a szolgáltatásaink használata során. A gyűjtött adatokat az OpenAI szolgáltatásain keresztül dolgozzuk fel, amelyek nagyméretű nyelvi modelleket használnak a tanulási folyamatok támogatására.

## 5. Adattovábbítás és adatfeldolgozás

Az Ön adatai a Google európai szerverein kerülnek tárolásra, és az OpenAI szolgáltatásait használjuk azok feldolgozására. Az adatok továbbítása kizárólag ezen partnerek felé történik, akik garantálják az adatok biztonságát és védelmét.

## 6. Az adatok tárolásának időtartama

Az adatokat a felhasználói fiók aktív státusza alatt tároljuk, vagy amíg azok törlését nem kéri. Az inaktív fiókok adatait a jogszabályok által előírt időn belül töröljük.

## 7. Az érintettek jogai

Ön jogosult hozzáférni a személyes adataihoz, kérheti azok helyesbítését, törlését vagy az adatkezelés korlátozását. Továbbá jogosult tiltakozni az adatkezelés ellen, valamint kérheti adatai hordozhatóságát.

## 8. Adatbiztonság

A GoSchool-AI Kft. az adatok védelme érdekében megfelelő technikai és szervezési intézkedéseket hozott, beleértve a titkosítást és az adatbiztonsági protokollokat.

## 9. Kapcsolatfelvétel adatvédelmi ügyekben

Amennyiben kérdése van az adatkezeléssel kapcsolatban, kérjük, lépjen kapcsolatba velünk az alábbi elérhetőségek egyikén:

- **E-mail**: info@goschool.ai
- **Postai cím**: GoSchool-AI Kft., 1121 Budapest, Ordas köz 2.

Köszönjük, hogy megbízik bennünk és szolgáltatásainkat használja!

Hatályos: 2024.08.30

`
