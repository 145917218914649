import { type SubmitState } from '../model'
import type { FormEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  styled,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { useChatContext } from './ChatContext'

export function PromptForm() {
  const { chatManager } = useChatContext()
  const [message, setMessage] = useState<string>('')

  const [submitState, setSubmitState] = useState<SubmitState>('idle')
  useEffect(() => {
    if (chatManager !== undefined) {
      chatManager.addEventListener('submitStateUpdate', setSubmitState)

      return () => {
        chatManager.removeEventListener('submitStateUpdate', setSubmitState)
      }
    }
  }, [chatManager])

  useEffect(() => {
    setMessage('')
  }, [chatManager])

  const sendMessage = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (submitState === 'idle') {
        chatManager.post(message)
        setMessage('')
      }
    },
    [chatManager, message, submitState]
  )

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      sendMessage(event)
    }
  }

  const submitButtonAdornment = (
    <InputAdornment position="end">
      <IconButton
        type="submit"
        color="primary"
        disableRipple
        sx={{ p: 2 }}
        onClick={sendMessage}
        disabled={submitState !== 'idle'}
      >
        <SendIcon />
      </IconButton>
    </InputAdornment>
  )

  return (
    <form onSubmit={sendMessage}>
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <PromptInput
          placeholder="Your message"
          id="user_message"
          multiline
          maxRows={4}
          minRows={1}
          value={message}
          onKeyDown={handleKeyDown}
          onChange={(e) => setMessage(e.target.value)}
          endAdornment={submitButtonAdornment}
        />
      </FormControl>
    </form>
  )
}

const PromptInput = styled(InputBase, { name: 'PromptInput', slot: 'Root' })(
  ({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    alignItems: 'flex-end',
  })
)
