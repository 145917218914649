import type { PropsWithChildren} from 'react'
import { useContext } from 'react'
import { createContext } from 'react'

export interface BrandingType {
  primaryLogo: string;
  secondaryLogo: string;
}

export const BrandingContext = createContext<BrandingType | undefined>(undefined)

export type BrandingContextProviderProps = PropsWithChildren & BrandingType

export function BrandingContextProvider(props: BrandingContextProviderProps) {
  const { children, ...branding } = props
  return <BrandingContext.Provider value={branding}>{children}</BrandingContext.Provider>
}

export function useBranding() {
  const context = useContext(BrandingContext)
  if (!context) {
    throw new Error('useBranding must be used within a BrandingContextProvider')
  }
  return context
}
