import { Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { useBranding } from '@goschool/site'

export function Welcome() {
  const {primaryLogo} = useBranding()
  return <>
    <img src={primaryLogo} alt="GoSchool.AI" style={{ maxWidth: '100%', height: 'auto' }} />
    <Typography variant="h6"><Trans i18nKey="site.title" /></Typography>
  </>
}
