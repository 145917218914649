import { Button, Stack } from '@mui/material'
import { Trans } from 'react-i18next'
import { useAuthenticationContext, useUserContext } from '@goschool/auth'
import { LoadingPage, PageLayout } from '@goschool/site'
import { CoursesPage } from '@goschool/courses'
import { Welcome } from '../branding'

export function App() {
  const { user, state } = useUserContext()
  const { signIn } = useAuthenticationContext()

  if (state==='pending') {
    return <LoadingPage />
  }

  if (user==null) {
    return <PageLayout fullScreen={true}>
      <Stack gap={2} justifyContent="center" alignItems="center" flexGrow={1}>
        <Welcome />
        <Button variant="contained" color="primary" onClick={signIn}><Trans i18nKey="auth:signIn.cta" /></Button>
      </Stack>
    </PageLayout>
  }

  return <CoursesPage />
}
