import { useCourseDocuments } from '@goschool/dao'
import AddIcon from '@mui/icons-material/Add'
import { useState } from 'react'
import { DocumentUploadDialog } from './components/DocumentUploadDialog'
import { FixedFab } from '@goschool/site'
import { CourseDocumenList } from './components/CourseDocumentList'
import { Stack, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

export function CourseDocuments() {
  const { courseDocuments } = useCourseDocuments()

  const [uploading, setUploading] = useState(false)
  if (courseDocuments==null) {
    return null
  }

  if (courseDocuments.length===0) {
    return <Stack>
      <Typography variant="h5" component="h3" gutterBottom={true}><Trans
        i18nKey="course:details.documents" /></Typography>
      <Typography variant="body1"><Trans i18nKey="course:details.no-documents" /></Typography>
      <FixedFab onClick={() => setUploading(true)} color="primary" horizontal="center" vertical="bottom">
        <AddIcon />
      </FixedFab>
      <DocumentUploadDialog displayed={uploading} hide={() => setUploading(false)} />
    </Stack>
  }


  return <Stack>
    <Typography variant="h5" component="h3" gutterBottom={true}><Trans
      i18nKey="course:details.documents" /></Typography>
    <CourseDocumenList courseDocuments={courseDocuments} />
    <FixedFab onClick={() => setUploading(true)} color="primary" horizontal="center" vertical="bottom">
      <AddIcon />
    </FixedFab>
    <DocumentUploadDialog displayed={uploading} hide={() => setUploading(false)} />
  </Stack>
}


