import { typeConverter } from '@progos/firebase-chat'
import type { DocumentReference, Timestamp } from 'firebase/firestore'
import type { GoSchoolUser } from './GoSchoolUser'

export interface Course {
  title: string
  code: string
  description: string
  language_code: string
  owner: string
  instructors: string[]
  students: string[]
  created_at: Timestamp
  banner?: string | null
}

export const courseConverter = typeConverter<Course>()


export interface CourseStudent {
  user: DocumentReference<GoSchoolUser>
  name: string
}
