import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import type { DocumentSnapshot } from 'firebase/firestore'
import type { Course } from '@goschool/model'


interface CourseContextType {
  courseSnapshot: DocumentSnapshot<Course> | undefined | null;
  course: Course | undefined | null;
}

export const CourseContext = createContext<CourseContextType | undefined>(undefined)

type CourseContextProviderProps = CourseContextType & PropsWithChildren

export function CourseContextProvider({ children, ...context }: CourseContextProviderProps) {
  return (
    <CourseContext.Provider value={context}>
      {children}
    </CourseContext.Provider>
  )
}

export function useCourseContext() {
  const context = useContext(CourseContext)
  if (context===undefined) {
    throw new Error('useCourseContext must be used within a CourseContextProvider')
  }
  return context
}
