import type { ChatManager } from '../model'
import type { PropsWithChildren, ReactNode} from 'react'
import { createContext, useContext } from 'react'


export type ContentComponentType =
  (props: { children: string | null | undefined }) => ReactNode


export interface ChatContextType {
  mode: 'chat' | 'view';
  ContentComponent?: ContentComponentType;
  samplePrompts?: string[];
  chatManager: ChatManager;
}


export interface ChatContextProviderProps extends PropsWithChildren {
  mode: 'chat' | 'view';
  ContentComponent?: ContentComponentType;
  samplePrompts?: string[];
  chatManager: ChatManager | undefined;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

export function ChatContextProvider({ children, chatManager, ...context }: ChatContextProviderProps) {
  if (chatManager == null){
    return null
  }

  return <ChatContext.Provider value={{ ...context, chatManager }}>{children}</ChatContext.Provider>
}

export function useChatContext() {
  const context = useContext(ChatContext)
  if (context==null) {
    throw new Error('useChatContext must be used within a ChatContextProvider')
  }
  return context
}
