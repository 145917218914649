import type { Timestamp } from 'firebase/firestore'
import { Trans } from 'react-i18next'

export function TransDate({ ts, defaultValue = '' }: { ts: Timestamp | undefined | null, defaultValue?: string }) {
  if (ts==null) {
    return defaultValue
  }

  return <Trans i18nKey="date" values={{ timestamp: ts.toDate() }} />
}

export function TransTime({ ts, defaultValue = '' }: { ts: Timestamp | undefined | null, defaultValue?: string }) {
  if (ts==null) {
    return defaultValue
  }

  return <Trans i18nKey="time" values={{ timestamp: ts.toDate() }} />
}

export function TransRelTime({ ts, defaultValue = '' }: { ts: Timestamp | undefined | null, defaultValue?: string }) {
  if (ts==null) {
    return defaultValue
  }
  const elapsed = Math.round((ts.toDate().getTime() - Date.now()) / 1000 / 24 / 60 / 60)

  return <Trans i18nKey="relativeTime" values={{ diff: elapsed }} />
}
