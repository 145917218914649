import { typeConverter } from '@progos/firebase-chat'
import type { DocumentReference, Timestamp } from 'firebase/firestore'


interface TransitionState {
  status: 'processing' | 'succeeded' | 'failed'
  progress?: number
  total?: number
}

export interface CourseDocumentState {
  preprocess_upload?: TransitionState
  extract_content?: TransitionState,
  transcribe_audio?: TransitionState
  content_analysis?: TransitionState,
  index_content?: TransitionState
}

export interface CourseDocument {
  title: string
  upload: DocumentReference<FileUpload>

  uploader?: string | null
  subtitle?: string | null
  authors?: string[] | null
  lead?: string | null
  abstract?: string | null
  content_type?: string | null
  storage_ref?: string | null

  availability?: 'public' | 'quote' | 'read' | 'private'

  state?: CourseDocumentState
}

export interface FileUpload {
  status: 'pending' | 'uploading' | 'uploaded' | 'failed'
  file_name: string
  size?: number
  storage_path: string | null
  created_at: Timestamp
}

export const courseDocumentConverter = typeConverter<CourseDocument>()
export const documentUploadConverter = typeConverter<FileUpload>()

