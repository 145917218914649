export const termsOfUse = `
# Terms of Service

## 1. Introduction

GoSchool-AI Kft. welcomes you to the [goschool.ai](https://goschool.ai) and [tilosazai.org](https://tilosazai.org) platforms. These Terms of Service (Terms) govern your use of our services, which are provided to pre-registered users, including teachers and students.

The purpose of the service is to support interactive, non-linear education and explore the possibilities within it to enable scientifically-backed, effective teaching.

## 2. Description of Service

As part of the service, teachers can upload educational materials for courses, which students can access via an intelligent chat interface. The chat interacts with students using large language models.

## 3. Data Processing and Research

Students and teachers consent to the use of their activities related to the use of the system for research purposes. During the research, user activities may be analyzed, and the results of the research may be published. Data analysis and publication are conducted in an anonymous manner.

## 4. Document Upload and Storage

GoSchool-AI Kft. does not review the legal status of uploaded documents and assumes no responsibility for any legal issues related to the documents. Users are responsible for ensuring they have the rights to use the documents they upload.

Uploaded documents are stored as long as the associated course is active.

Uploaded documents will not be shared with third parties unless required by law.

## 5. Limitation of Liability

GoSchool-AI Kft. assumes no responsibility for any indirect, incidental, consequential, or punitive damages that may arise in connection with the use of the service. The user is fully responsible for any damages incurred during the use of the service.

## 6. Modifications

GoSchool-AI Kft. reserves the right to modify these Terms from time to time. The current Terms will always be available on our service. Continued use of the service after the changes take effect constitutes acceptance of the modified Terms.

## 7. Contact

If you have any questions or comments, please contact us through one of the following methods:

- **Email**: info@goschool.ai
- **Postal Address**: GoSchool-AI Kft., 1121 Budapest, Ordas köz 2.

Please read the Terms of Service carefully. By using our services, you agree to the Terms outlined herein.

Thank you for choosing the services of GoSchool-AI Kft.!

Effective: 30 August 2024
`
