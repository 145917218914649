export const termsOfUse = `
# Felhasználási Feltételek

## 1. Bevezetés
   
A GoSchool-AI Kft. üdvözli Önt a [goschool.ai](https://goschool.ai) és a [tilosazai.org](https://tilosazai.org) platformokon. Ezen Felhasználási Feltételek 
(Feltételek) szabályozzák az Ön által használt szolgáltatásainkat, 
amelyeket az előre regisztrált felhasználók - beleértve a tanárokat és diákokat - számára nyújtunk. 

A szolgáltatás célja, hogy támogassa az interaktív, nem lineáris oktatást és kutassa az ebben rejlő lehetőségeket annak érdekében, hogy tudományosan alátámasztott, hatékony oktatást tegyünk lehetővé.

## 2. Szolgáltatás Leírása
A szolgáltatás keretében a tanárok kurzusokhoz tananyagot tölthetnek fel, 
amelyehez a diákok intelligens chat felületen keresztül férnek hozzá.
Az chat nagy nyelvi modellek használatával társalog a diákokkal.

   
## 3. Adatkezelés és Kutatás
A diákok és tanárok beleegyeznek, hogy a rendszer használatával kapcsolatos tevékenységeiket felhasználhatjuk kutatási célokra.
A kutatások során a felhasználói tevékenységet elemezhetjük és a kutatás eredményét publikálhatjuk. 
Az adatok elemzése és publikálása anonim módon történik.


## 4. Dokumentumok Feltöltése és Tárolása

A GoSchool-AI Kft. nem vizsgálja a feltölött dokumentumok jogi státuszát és nem vállal felelősséget semmilyen jogi problémáért, amely a dokumentumokkal kapcsolatos.
A felhasználók  felelősek azért, hogy az általuk feltöltött dokumentumok felhasználási jogaival rendelkezzenek. 

A feltöltött dokumentumokat a addig tároljuk, amíg a kapcsolódó kurzus aktív.

A feltöltött dokumentumokat nem adjuk át harmadik félnek, kivéve, ha erre jogszabály kötelez bennünket. 

## 5. Felelősség Korlátozása
A GoSchool-AI Kft. nem vállal felelősséget semmilyen közvetett, véletlenszerű, következményes vagy büntető jellegű kárért, amely a szolgáltatás használatával kapcsolatosan merülne fel. A szolgáltatás használata során keletkező bármilyen kárért a felhasználó teljes mértékben felelős.

## 6. Módosítások
A GoSchool-AI Kft. fenntartja a jogot, hogy ezen Feltételeket időről időre módosítsa. Az aktuális Feltételek mindig elérhetők a szolgáltatásunkon. A változtatások hatályba lépését követően a szolgáltatás használata a módosított Feltételek elfogadásának minősül. 
   
## 7. Kapcsolat
Bármilyen kérdés vagy észrevétel esetén, kérjük, lépjen kapcsolatba velünk az alábbi elérhetőségek egyikén:

- **E-mail**: info@goschool.ai
- **Postai cím**: GoSchool-AI Kft., 1121 Budapest, Ordas köz 2.
   
Kérjük, hogy alaposan olvassa el a Felhasználási Feltételeket. A szolgáltatásaink használatával Ön elfogadja a Feltételekben foglaltakat.

Köszönjük, hogy a GoSchool-AI Kft. szolgáltatásait választotta!

Hatályos: 2024.08.30

`
