
export const Routing = {
  home: '/',
  courses: '/courses',
  termsOfUse: '/terms-of-use',
  admin: '/admin',
  privacyStatement: '/privacy-statement',
  invitations: '/invitations',
  invitation: '/invitations/:invitationId',
  organization: '/organizations/:organizationId',
  course: '/organizations/:organizationId/courses/:courseId',
  document: '/organizations/:organizationId/courses/:courseId/documents/:documentId',
  courseChat: '/organizations/:organizationId/courses/:courseId/chat',
  courseStudents: '/organizations/:organizationId/courses/:courseId/students',
}

export * as GoSchool from './routes'



