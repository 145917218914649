import { useUserContext } from './UserContext'
import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'
import { SignUpForm, SignInForm, PasswordResetForm } from './components'
import { signOut as firebaseSignOut,  } from 'firebase/auth'
import { GoSchoolDialog } from '@goschool/mui'


type AuthenticationMode = 'signIn' | 'signUp' | 'resetPassword'
export type AuthProvider = 'google' | 'microsoft' | 'email-password'

export interface AuthenticationContextType {
  authenticationMode: AuthenticationMode | null;

  enabledMethods: AuthProvider[]
  signIn: () => void;
  signUp: () => void;
  signOut: () => void;
  resetPassword: () => void;

  hideAuthentication: () => void;
}

export const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined)


export interface AuthenticationContextProviderProps extends PropsWithChildren {
  enabledMethods: AuthProvider[]
  defaultMode?: AuthenticationMode
}
export function AuthenticationContextProvider({ enabledMethods, defaultMode, children }: AuthenticationContextProviderProps) {
  const { auth } = useUserContext()

  const [authenticationMode, setAuthenticationMode] = useState<AuthenticationMode | null>(
    defaultMode ?? null
  )
  const signIn = useCallback(() => setAuthenticationMode('signIn'), [])
  const signUp = useCallback(() => setAuthenticationMode('signUp'), [])
  const resetPassword = useCallback(() => setAuthenticationMode('resetPassword'), [])
  const hideAuthentication = useCallback(() => setAuthenticationMode(null), [])
  const signOut = useCallback(() => {
    firebaseSignOut(auth)
  }, [auth])


  return <AuthenticationContext.Provider
    value={{ enabledMethods, authenticationMode, signIn, signUp, resetPassword, hideAuthentication, signOut }}>
    {children}
    <GoSchoolDialog open={authenticationMode!=null} onClose={hideAuthentication} fullWidth={true}>{
      authenticationMode==='signIn'
        ? <SignInForm />
        :authenticationMode==='signUp'
          ? <SignUpForm />
          :<PasswordResetForm />
    }</GoSchoolDialog>
  </AuthenticationContext.Provider>
}



export function useAuthenticationContext() {
  const context = useContext(AuthenticationContext)
  if (context==null) {
    throw new Error('useAuthenticationContext must be used within a AuthenticationContextProvider')
  }
  return context
}
