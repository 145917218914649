import { useUserContext } from '@goschool/auth'
import { type PropsWithChildren, type ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { LoadingPage, PageLayout } from '@goschool/site'
import { Fab, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import { typeConverter, useFirestore, useFirestoreResults, useFirestoreSnapshot } from '@progos/firebase-chat'
import type { User } from 'firebase/auth'
import { and, collection, or, query, Timestamp, where } from 'firebase/firestore'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { GoSchoolInvitation } from '@goschool/model'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import ShareIcon from '@mui/icons-material/Share'
import { TransRelTime } from '@goschool/i18n'

export function InvitationsPage() {
  const { roles, user } = useUserContext()
  const [creating, setCreating] = useState(false)

  const { invitations } = useInvitations(user)
  const { t } = useTranslation()

  if (invitations==null) {
    return <LoadingPage />
  }


  if ((invitations ?? []).length===0) {
    if (roles.includes('instructor') || roles.includes('admin')) {
      return <>
        <WarningPage icon={<ShareIcon />} title={t('auth:warnings.noInvutations.title')}>
          <>
            <Trans i18nKey="auth:warnings.noInvutations.description" />
            <Fab color="primary" sx={{ marginLeft: '1rem' }} onClick={() => setCreating(true)}><AddIcon /></Fab>
          </>
        </WarningPage>
        {/*<CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />*/}
      </>
    } else {
      return <WarningPage icon={<MeetingRoomIcon />} title={t('auth:warnings.noInvitationsGeOne.title')}>
        <Trans i18nKey="auth:warnings.noInvitationsGeOne.description" />
      </WarningPage>
    }
  }

  return <PageLayout fullScreen={false}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="auth:invitations.title" /></Typography>

    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('auth:invitations.tableHeaders.name')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.course')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.accepted')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.created_at')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.expires_at')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.roles')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(invitations ?? []).map((invitation) => <InvitationRow key={invitation.id} snapshot={invitation} />)}
        </TableBody>
      </Table>
    </TableContainer>

    {/*<CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />*/}


    {/*<CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />*/}
  </PageLayout>
}


function InvitationRow({ snapshot }: { snapshot: QueryDocumentSnapshot<GoSchoolInvitation> }) {
  const invitation = useMemo(() => snapshot.data(), [snapshot])
  const course = useFirestoreSnapshot(invitation.course)
  const acceptedCollection = useMemo(
    () => collection(snapshot.ref, 'acceptance'),
    [snapshot]
  )


  const accepted = useFirestoreResults(acceptedCollection)

  return <TableRow>
    <TableCell>{invitation.name}</TableCell>
    <TableCell>{course?.data()?.title}</TableCell>
    <TableCell>{invitation.name != null
      ? (accepted?.length??0) > 0 ? '✓' :null
      : accepted?.length ?? null}</TableCell>
    <TableCell><TransRelTime ts={invitation.created_at}/></TableCell>
    <TableCell><TransRelTime ts={invitation.expires_at}/></TableCell>
    <TableCell>{invitation.roles.join(', ')}</TableCell>
  </TableRow>

}

function useInvitations(user: User | undefined | null) {
  const firestore = useFirestore()
  const invitationsCollection = useMemo(
    () => {
      return collection(firestore, 'invitations').withConverter(typeConverter<GoSchoolInvitation>())
    }, [firestore]
  )

  const invitationsFilter = useMemo(
    () => {
      if (user===null) {
        return null
      }
      if (user===undefined) {
        return undefined
      }

      return query(
        invitationsCollection,
        and(
          where('created_by', '==', user.uid),
          or(
            where('expires_at', '==', null),
            where('expires_at', '>', Timestamp.now())
          )
        )
      )
    }, [invitationsCollection, user]
  )

  const invitations = useFirestoreResults(invitationsFilter)
  return { invitationsCollection, invitations }
}


function WarningPage({ icon, title, children }: PropsWithChildren<{ icon: ReactNode, title: string }>) {
  return <PageLayout fullScreen={true} centered={true}>
    <Stack direction="row" gap={2} alignItems="center" justifyContent="stretch">
      {/*<WarningAvatar>{icon}</WarningAvatar>*/}
      <Typography variant="h3" component="h1">
        {title}
      </Typography>
    </Stack>
    <Typography variant="h5" component="p">{children}</Typography>
  </PageLayout>
}
