import { Container, Stack, styled, Typography } from '@mui/material'
import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import type { DocumentReference } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import * as Sentry from '@sentry/react'
import { HttpError } from '@progos/firebase-chat'
import { FirebaseAppContext } from '@progos/firebase-chat/src/lib/firebase/FirebaseAppContext'
import { InternalError } from '@progos/firebase-chat/src/lib/errors/errors'


interface ErrorBoundaryProps {
  children?: ReactNode
  errorComponent?: (props: { error: HttpError }) => ReactNode
}

interface ErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
  reference?: DocumentReference
}

export class SentryReportingErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private ErrorComponent: ((props: { error: HttpError }) => React.ReactNode) | (({error}: {
    error: HttpError
  }) => ReactNode)

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.ErrorComponent = props.errorComponent ?? ErrorComponent
  }

  readonly state: ErrorBoundaryState = {}
  static contextType = FirebaseAppContext
  declare context: React.ContextType<typeof FirebaseAppContext>

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {error}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({error, errorInfo})
    this.reportError(error, errorInfo)
  }

  private getUser() {
    if (this.context == null) {
      return null
    }
    const {app} = this.context
    const auth = getAuth(app)
    return auth.currentUser
  }
  private async reportError(error: Error, errorInfo: ErrorInfo) {

    Sentry.captureException(
      error,
      {
        user: {id: this.getUser()?.uid},
        extra: {
          errorInfo,
          href: window.location.href,
        }
      }
    )
  }

  public render() {
    if (this.state.error != null) {

      const error = this.state.error instanceof HttpError
        ? this.state.error
        : new InternalError()

      return <this.ErrorComponent error={error}/>
    }

    return this.props.children
  }
}

function ErrorComponent({error}: { error: HttpError }) {
  return <ErrorContainer maxWidth="sm">
    <Stack direction="row" gap={2} alignItems="center" justifyContent="stretch" width="100%">
      <H3ErrorIcon/>
      <Typography variant="h3" component="h1">{error.status} {error.title} </Typography>
    </Stack>
    <Typography variant="body2">{error.message}</Typography>
  </ErrorContainer>
}

const H3ErrorIcon = styled(ErrorIcon, {
  name: 'H3ErrorIcon',
  slot: 'Root'
})(({theme}) => ({
  color: theme.palette.error.main,
  fontSize: theme.typography.h3.fontSize
}))

const ErrorContainer = styled(Container, {
  name: 'ErrorContainer',
  slot: 'Root'
})(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100vw', height: '100vh', overflow: 'hidden'
}))


