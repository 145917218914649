import { Skeleton, Stack, Typography } from '@mui/material'
import { LoadingPage, PageLayout } from '@goschool/site'
import { useParams } from 'react-router-dom'
import { NotFound, useFirestoreSnapshot } from '@progos/firebase-chat'
import { CourseContextProvider, useCourseRef } from '@goschool/dao'
import { useMemo } from 'react'
import { GoSchool } from '@goschool/routing'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import type { Course } from '@goschool/model'
import { CourseDocuments } from './documents'

export function coursePageRef(courseSnapshot: QueryDocumentSnapshot<Course>) {
  return {
    title: courseSnapshot.data().title,
    href: GoSchool.course(courseSnapshot.ref)
  }
}

export function CoursePage() {
  const courseRef = useCourseRef(useParams<{
    organizationId: string
    courseId: string
  }>())

  const courseSnapshot = useFirestoreSnapshot(courseRef)
  const course = useMemo(
    () => courseSnapshot===null ? null:courseSnapshot?.data(),
    [courseSnapshot]
  )

  if (courseSnapshot==null) {
    return LoadingPage()
  }

  if (!courseSnapshot.exists()) {
    throw new NotFound()
  }


  if (course===null) {
    throw new NotFound()
  }

  return <PageLayout
    fullScreen={false}
    breadcrumbs={[coursePageRef(courseSnapshot)]}>
    <Stack>
      <Typography variant="h4" component="h1">{course?.title ?? <Skeleton />}</Typography>
      <Typography variant="subtitle1">{course?.code ?? <Skeleton />}</Typography>
      <Typography variant="body1">{course?.description}</Typography>
    </Stack>
    <CourseContextProvider courseSnapshot={courseSnapshot} course={course}>
      <CourseDocuments />
    </CourseContextProvider>
  </PageLayout>
}
