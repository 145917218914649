import { Button, Dialog, FormHelperText, styled } from '@mui/material'

export const GoSchoolDialog = styled(Dialog, { name: 'GoSchoolDialog', slot: 'Root' })(
  ({ theme }) => ({
    '& .MuiDialog-paper': {
      padding: theme.spacing(4)
    },
    '& .MuiDialogTitle-root': {
      padding: theme.spacing(2)
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2)
    }
  })
)

export const NoWrapFormHelperText = styled(FormHelperText, { name: 'NoWrapFormHelperText' })(
  ({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)

export const NoWrapButton = styled(Button, { name: 'NoWrapButton' })(
  ({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)
