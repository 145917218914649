import type { User, Auth } from 'firebase/auth'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { typeConverter, useFirebaseAuth, useFirestore, useFirestoreSnapshot } from '@progos/firebase-chat'
import type { GoSchoolUser, GoSchoolUserRole } from '@goschool/model'
import { doc } from 'firebase/firestore'


export interface UserContextType { // extends FirebaseAuthContextType
  user?: User | null;
  state: 'pending' | 'success' | 'error';
  auth: Auth;
  roles: GoSchoolUserRole[];
  goSchoolUser: GoSchoolUser | undefined | null;
}


export const UserContext =
  createContext<UserContextType | undefined>(undefined)


export function UserContextProvider({ children }: PropsWithChildren) {
  const firebaseAuth = useFirebaseAuth()
  const [roles, setRoles] = useState<GoSchoolUserRole[]>([])
  useEffect(() => {
    const { user } = firebaseAuth
    if (user!=null) {
      user?.getIdTokenResult().then((token) => {
        if ('roles' in token.claims) {
          setRoles((roles) => token.claims.roles as GoSchoolUserRole[])
        }
      })
    }
  }, [firebaseAuth])

  const firestore = useFirestore()

  const userRef = useMemo(
    () => {
      const user = firebaseAuth.user
      if (user!=null) {
        return doc(firestore, 'users', user.uid).withConverter(typeConverter<GoSchoolUser>())
      }
    }, [firebaseAuth.user, firestore]
  )

  const userSnapshot = useFirestoreSnapshot(userRef, { waitForWrites: true })

  const goSchoolUser = useMemo(
    () => userSnapshot===undefined
      ? undefined
      :userSnapshot?.data() ?? null,
    [userSnapshot]
  )

  return <UserContext.Provider value={{ ...firebaseAuth, roles, goSchoolUser }}>{children}</UserContext.Provider>
}

export function useUserContext() {
  const context = useContext(UserContext)
  if (context==null) {
    throw new Error('useAuthContext must be used within an AuthContextProvider')
  }
  return context
}
