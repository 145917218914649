import type { AppBarProps } from '@mui/material'
import { AppBar as MuiAppBar, IconButton, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { PageBreadcrumbs, PageLayout } from '@goschool/site'
import { useParams } from 'react-router-dom'
import { useCourseRef } from '@goschool/dao'
import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import type { DocumentReference, DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore'
import { collection, doc, orderBy, query, serverTimestamp, setDoc, where } from 'firebase/firestore'
import type { Course, CourseChat } from '@goschool/model'
import { AuthButton, useUserContext } from '@goschool/auth'

import { CourseChatList } from './components/CourseChatList'
import type { Chat } from '@goschool/shared/chat'
import { ChatContextProvider, ChatManager, ChatMessages, ChatPrompt } from '@goschool/shared/chat'
import { typeConverter, useFirestoreResults, useFirestoreSnapshot } from '@progos/firebase-chat'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import EditIcon from '@mui/icons-material/Edit'
import { GoSchool } from '@goschool/routing'
import type { User } from 'firebase/auth'
import { coursePageRef } from '@goschool/courses'


export function CourseChatPage() {
  const courseRef = useCourseRef(useParams<{
    organizationId: string
    courseId: string
  }>())


  const courseSnapshot = useFirestoreSnapshot(courseRef)

  if (courseSnapshot==null || courseRef==null || !courseSnapshot.exists()) {
    return null
  }

  return <CourseChatLayout courseSnapshot={courseSnapshot} />
}

function chatBreadcrumbs(
  courseSnapshot: QueryDocumentSnapshot<Course>,
  selectedChat: DocumentSnapshot<CourseChat> | null | undefined) {
  return [
    coursePageRef(courseSnapshot),
    {
      title: selectedChat?.data()?.title ?? 'New Chat',
      href: GoSchool.courseChat(courseSnapshot.ref)
    }
  ]
}

function CourseChatLayout({ courseSnapshot }: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down('md'))
  const [isOpen, setOpen] = useState<boolean>(!isNarrow)
  const toggleOpen = useCallback(() => setOpen((open) => !open), [setOpen])

  const sidebarWidth = 300

  useLayoutEffect(() => setOpen(!isNarrow), [isNarrow])

  const handleChatSelect = useCallback(
    () => {
      if (isNarrow) {
        setOpen(false)
      }
    }, [isNarrow])

  const { chats, selectedChat, selectChat, chatManager, createChat } = useChats(courseSnapshot, handleChatSelect)
  const chatSnapshot = useFirestoreSnapshot(selectedChat)

  return <ChatContextProvider chatManager={chatManager} mode="chat">
    <PageLayout fullScreen={true}>
      <AppBar position="static" sidebarWidth={sidebarWidth} isSidebarOpen={isOpen}>
        <Toolbar variant="dense" disableGutters={true}>
          <IconButton edge="start" size="small" onClick={toggleOpen}><ViewListRoundedIcon /></IconButton>
          <IconButton edge="end" size="small" onClick={createChat}><EditIcon /></IconButton>
        </Toolbar>
        <PageBreadcrumbs breadcrumbs={chatBreadcrumbs(courseSnapshot, chatSnapshot)} />

        <AuthButton />
      </AppBar>
      <ChatLayout sidebarWidth={sidebarWidth} isSidebarOpen={isOpen}>
        <Sidebar>
          <CourseChatList chats={chats} selectedChat={selectedChat} selectChat={selectChat} />
        </Sidebar>
        <Content>
          <div className="Chat-Messages"><ChatMessages /></div>
          <div className="Chat-Prompt"><ChatPrompt /></div>
        </Content>
      </ChatLayout>
    </PageLayout>
  </ChatContextProvider>
}


function Sidebar({ children }: PropsWithChildren) {
  return <div className="ChatLayout-Sidebar">
    {children}
  </div>
}


function Content({ children }: PropsWithChildren) {
  return <div className="ChatLayout-Content">
    {children}
  </div>
}

interface ChatLayoutProps extends PropsWithChildren {
  sidebarWidth: number;
  isSidebarOpen: boolean;
}

const AppBar = styled(MuiAppBar, {
  name: 'AppBar',
  slot: 'Root',
  shouldForwardProp: (propName) => propName!=='sidebarWidth' && propName!=='isSidebarOpen'
})<ChatLayoutProps & AppBarProps>(
  ({ theme, sidebarWidth, isSidebarOpen }) => ({
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiToolbar-root': {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: isSidebarOpen ? `${sidebarWidth}px`:'auto'
    },
    '& .MuiBreadcrumbs-root': {
      flex: '1 1 0',
      display: 'flex',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden'
    }
  })
)


const ChatLayout = styled(
  'div', {
    name: 'ChatLayout',
    slot: 'Root',
    shouldForwardProp: (propName) => propName!=='sidebarWidth' && propName!=='isSidebarOpen'
  })<ChatLayoutProps>(
  ({ theme, sidebarWidth, isSidebarOpen }) => ({
    flex: '1 0 auto',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    overflow: 'hidden',

    '& .ChatLayout-Sidebar': {
      display: 'flex',
      flegGrow: 0,
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: isSidebarOpen ? `${sidebarWidth}px`:0,

      '& > *': {
        flex: '1 1 0'
      }
    },
    '& .ChatLayout-Content': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0',
      gap: theme.spacing(0),
      backgroundColor: theme.palette.background.default,
      '& > .Chat-Messages': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        flex: '1 1 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(2)
      },
      '& > .Chat-Prompt': {
        flex: '0 0 200ox',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(2)
      }
    }
  })
)


function useChats(courseSnapshot: QueryDocumentSnapshot<Course>, handleChatSelect?: () => void) {
  const { user } = useUserContext()
  const collectionRef = useMemo(
    () =>
      collection(courseSnapshot.ref, 'chats')
        .withConverter(typeConverter<CourseChat>()),
    [courseSnapshot]
  )

  const chatsRef = useMemo(
    () => {
      if (user==null) {
        return null
      }
      return query(
        collectionRef,
        where('participants', 'array-contains', user.uid),
        orderBy('updated_at', 'desc')
      )
    },
    [collectionRef, user]
  )


  const chats = useFirestoreResults(chatsRef, { waitForWrites: true })


  const [selectedChat, setSelectedChat] = useState<DocumentReference<CourseChat>>()

  const selectChat = useCallback(
    (chat: DocumentReference<CourseChat>) => {
      setSelectedChat(chat)
      handleChatSelect?.()
    },
    [setSelectedChat, handleChatSelect]
  )

  const createChat = useCallback(
    () => selectChat(doc(collectionRef)),
    [collectionRef, selectChat]
  )

  useEffect(
    () => {
      if (chats!=null) {
        if (selectedChat==null) {
          if (chats.length > 0) {
            selectChat(chats[0].ref)
          } else {
            createChat()
          }
        }
      }
    },
    [selectedChat, chats, createChat, selectChat]
  )

  const chatManager = useChatManager(user, selectedChat)


  return { collectionRef, chats, selectedChat, selectChat, createChat, chatManager }
}


function useChatManager(user: User | null | undefined, selectedChat: DocumentReference<Chat> | undefined) {

  const initChat = useCallback(
    async (chatRef: DocumentReference<CourseChat>) => {
      await setDoc(
        chatRef, {
          created_at: serverTimestamp(), updated_at: serverTimestamp(),
          title: null,
          created_by: user?.uid ?? null,
          participants: user!=null ? [user.uid]:[],
          submit_state: 'idle'
        }
      )
    },
    [user]
  )

  const [chatManager, setChatmanager] = useState<ChatManager>()
  useEffect(() => {
    if (selectedChat!==undefined && user!==undefined) {
      const manager = new ChatManager(selectedChat, user, initChat)
      setChatmanager(manager)

      return () => {
        manager.dispose()
      }
    }
  }, [initChat, selectedChat, user])

  return chatManager
}

