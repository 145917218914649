import { Box, styled } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import type { ComponentProps } from 'react'

export function MessageMarkdownComponent({
  children,
}: Pick<ComponentProps<typeof ReactMarkdown>, 'children'>) {
  return (
    <Box sx={{ px: 4 }}>
      <StyledMarkdown>{children}</StyledMarkdown>
    </Box>
  )
}

const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  ...theme.typography.body2,
}))
