import { useChatContext } from './ChatContext'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid2 as Grid
} from '@mui/material'
import { shuffle } from 'lodash'
import { useCallback, useMemo } from 'react'

export function SamplePrompts() {
  const { samplePrompts, chatManager } = useChatContext()

  const selected = useMemo(
    () => shuffle(samplePrompts ?? []).slice(0, 4),
    [samplePrompts]
  )

  const sendMessage = useCallback(
    (message: string) => () => chatManager.post(message),
    [chatManager]
  )

  if (samplePrompts==null) {
    return null
  }

  return (
    <Box margin="auto">
      <Grid container spacing={4} rowSpacing={4}>
        {selected.map((prompt, i) => (
          <Grid size={{ xs: 12, md: 6, xl: 3 }} key={i}>
            <Card>
              <CardActionArea onClick={sendMessage(prompt)}>
                <CardContent sx={{ height: '6rem' }}>
                  <Typography variant="body2">{prompt}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
