type EventMap = object;

type EventKey<T extends EventMap> = string & keyof T;
type EventReceiver<T> = (params: T) => unknown;
type ListenerMap<T extends EventMap> = {
  [K in keyof T]?: Array<EventReceiver<T[K]>>;
};

export interface Emitter<T extends EventMap> {
  addEventListener<K extends EventKey<T>>(
    eventName: K,
    fn: EventReceiver<T[K]>
  ): void;

  removeEventListener<K extends EventKey<T>>(
    eventName: K,
    fn: EventReceiver<T[K]>
  ): void;

  emit<K extends EventKey<T>>(eventName: K, params: T[K]): void;
}

export abstract class EventEmitter<T extends EventMap> implements Emitter<T> {
  private listeners: ListenerMap<T> = {}

  addEventListener<K extends EventKey<T>>(
    eventName: K,
    fn: EventReceiver<T[K]>
  ) {
    const listeners: EventReceiver<T[K]>[] = this.listeners[eventName] || []
    if (fn == null || typeof fn !== 'function') {
      console.warn('Event listener is not a function')
    }
    this.listeners[eventName] = listeners.concat(fn)
  }

  removeEventListener<K extends EventKey<T>>(
    eventName: K,
    fn: EventReceiver<T[K]>
  ) {
    const listeners: EventReceiver<T[K]>[] = this.listeners[eventName] || []
    this.listeners[eventName] = listeners.filter((f) => f !== fn)
  }

  emit<K extends EventKey<T>>(eventName: K, event: T[K]) {
    const listeners: EventReceiver<T[K]>[] = this.listeners[eventName] || []
    listeners.forEach((fn) => fn && fn(event))
  }
}
