import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import type { DocumentSnapshot } from 'firebase/firestore'
import type { CourseDocument } from '@goschool/model'


interface CourseDocumentContextType {
  courseDocumentSnapshot: DocumentSnapshot<CourseDocument> | undefined | null;
  courseDocument: CourseDocument | undefined | null;
}

export const CourseDocumentContext = createContext<CourseDocumentContextType | undefined>(undefined)

type CourseDocumentContextProviderProps = CourseDocumentContextType & PropsWithChildren

export function CourseDocumentContextProvider({ children, ...context }: CourseDocumentContextProviderProps) {
  return (
    <CourseDocumentContext.Provider value={context}>
      {children}
    </CourseDocumentContext.Provider>
  )
}

export function useCourseDocumentContext() {
  const context = useContext(CourseDocumentContext)
  if (context===undefined) {
    throw new Error('useCourseDocumentContext must be used within a CourseDocumentContextProvider')
  }
  return context
}
