import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { useMemo } from 'react'

import docs from './content'
import { GoSchool } from '@goschool/routing'
import { PageLayout } from '../PageLayout'

export function termsOfUseBreadcrumbs(title: string) {
  return [{
    title,
    href: GoSchool.termsOfUse
  }]
}

export function TermsOfUse() {
  const md = useLegalDoc('termsOfUse')
  const { t } = useTranslation()

  return <PageLayout fullScreen={false} breadcrumbs={termsOfUseBreadcrumbs(t('legal:termsOfUse'))}>
    <StyledMarkdown>{md}</StyledMarkdown>
  </PageLayout>

}

export function privacyStatementBreadcrumbs(title: string) {
  return [{
    title,
    href: GoSchool.privacyStatement
  }]
}


export function PrivacyStatement() {
  const md = useLegalDoc('privacyStatement')
  const { t } = useTranslation()


  return <PageLayout
    fullScreen={false}
    breadcrumbs={privacyStatementBreadcrumbs(t('legal:privacyStatement'))}>
    <StyledMarkdown>{md}</StyledMarkdown>
  </PageLayout>
}


function useLegalDoc(name: keyof typeof docs.en) {
  const { i18n } = useTranslation()

  const selectedLanguage = useMemo(
    () => {
      const language = i18n.language.split('-')[0]
      if (language in docs) {
        return docs[language as keyof typeof docs]
      } else {
        return docs.en
      }
    }, [i18n]
  )

  return selectedLanguage[name]
}

const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  ...theme.typography.body2
}))
