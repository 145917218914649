import type { PropsWithChildren} from 'react'
import { createContext, useContext } from 'react'
import type { DocumentSnapshot } from 'firebase/firestore'
import type { Organization } from '@goschool/model'


interface OrganizationContextType {
  organizationSnapshot: DocumentSnapshot<Organization> | undefined | null;
  organization: Organization | undefined | null;
}

export const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined)

type OrganizationContextProviderProps = PropsWithChildren & OrganizationContextType

export function OrganizationContextProvider({ children, ...context }: OrganizationContextProviderProps) {
  return (
    <OrganizationContext.Provider value={context}>
      {children}
    </OrganizationContext.Provider>
  )
}

export function useOrganizationContext() {
  const context = useContext(OrganizationContext)
  if (context===undefined) {
    throw new Error('useOrganizationContext must be used within a OrganizationContextProvider')
  }
  return context
}
