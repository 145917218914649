import type {
  QueryDocumentSnapshot
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  type DocumentReference,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore'
import type { Course, GoSchoolInvitation, Organization } from '@goschool/model'
import { Box, Button, Dialog, DialogContent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Trans } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { GoSchool } from '@goschool/routing'
import { QRCode } from 'react-qrcode-logo'
import { typeConverter, useFirebaseAnalytics, useFirestore, useFirestoreSnapshot } from '@progos/firebase-chat'
import { useUserContext } from '@goschool/auth'
import { logEvent } from 'firebase/analytics'

interface CourseInvitationDialogProps {
  displayed: boolean;
  hide: () => void;
  course: QueryDocumentSnapshot<Course>;
}

export function QRInvitationDialog({ displayed, hide, course }: CourseInvitationDialogProps) {
  const { invitation, createInvitation, isCreating } = useCourseInvitation(course)

  const theme = useTheme()
  const isLG = useMediaQuery(theme.breakpoints.up('lg'))

  const size = isLG ? 512:256
  const { analytics } = useFirebaseAnalytics()

  useEffect(
    () => {
      if (invitation?.exists()) {
        logEvent(analytics, 'invitation_qr_created', {
          course_id: course.id,
          invitation_id: invitation.id
        })
      }
    }, [analytics, course.id, invitation]
  )

  return <Dialog open={displayed} maxWidth="xl" fullWidth={false} onClose={hide}>
    <DialogContent>
      <Stack direction="column" gap={2} alignItems="center" justifyContent="stretch">
        <Typography flexGrow={0} variant="h4"><Trans i18nKey="auth:invitation.form.joinCourse"
                                                     values={{ courseTitle: course.data().title }} /></Typography>
        <Stack flexGrow={1} flexShrink={0} gap={2} minHeight={size} minWidth={size} alignItems="center"
               justifyContent="center">
          {
            invitation?.exists()
              ? <InvitationQR invitation={invitation} size={size} />
              :<LoadingButton variant="contained" onClick={createInvitation} loading={isCreating}>
                Create
              </LoadingButton>
          }

        </Stack>
        <Box flexGrow={0}><Button variant="outlined" onClick={hide}><Trans
          i18nKey="auth:invitation.form.close" /></Button></Box>
      </Stack>
    </DialogContent>
  </Dialog>
}

export function LinkInvitationDialog({ displayed, hide, course }: CourseInvitationDialogProps) {
  const expiration = 72
  const { invitation, createInvitation, isCreating } = useCourseInvitation(course, expiration)

  const { analytics } = useFirebaseAnalytics()

  useEffect(
    () => {
      if (invitation?.exists()) {
        logEvent(analytics, 'invitation_link_created', {
          course_id: course.id,
          invitation_id: invitation.id
        })
      }
    }, [analytics, course.id, invitation]
  )
  return <Dialog open={displayed} maxWidth="xl" fullWidth={false} onClose={hide}>
    <DialogContent>
      <Stack direction="column" gap={2} alignItems="center" justifyContent="stretch">
        <Typography flexGrow={0} variant="h4"><Trans i18nKey="auth:invitation.form.inviteToCourse"
                                                     values={{ courseTitle: course.data().title }} /></Typography>
        <Stack flexGrow={1} flexShrink={0} gap={2} alignItems="center" justifyContent="center">
          {
            invitation?.exists()
              ? <InvitationLink invitation={invitation} />
              :<LoadingButton variant="text" onClick={createInvitation} loading={isCreating}>
                Create
              </LoadingButton>
          }

        </Stack>
        <Box flexGrow={0}><Button variant="outlined" onClick={hide}><Trans
          i18nKey="auth:invitation.form.close" /></Button></Box>
      </Stack>
    </DialogContent>
  </Dialog>
}

function InvitationQR({ invitation, size }: { invitation: QueryDocumentSnapshot<GoSchoolInvitation>, size: number }) {
  const url = useMemo(
    () => `${window.location.origin}${GoSchool.invitation(invitation.ref)}`, [invitation]
  )

  const theme = useTheme()


  return <QRCode
    size={size} qrStyle="fluid"
    logoImage="/site-logo.png" logoPaddingStyle="circle" removeQrCodeBehindLogo={true}
    logoWidth={32} logoHeight={32} logoPadding={2}
    bgColor={theme.palette.background.default}
    fgColor={theme.palette.primary.main}
    eyeColor={theme.palette.secondary.main}
    style={{ height: 'auto', maxWidth: size, width: size }}
    value={url}
  />
}


function InvitationLink({ invitation }: { invitation: QueryDocumentSnapshot<GoSchoolInvitation> }) {
  const url = useMemo(
    () => `${window.location.origin}${GoSchool.invitation(invitation.ref)}`, [invitation]
  )
  return <>
    <Typography variant="h6" onClick={() => navigator.clipboard.writeText(url)}>{url}</Typography>
    <Typography variant="body1"><Trans
      i18nKey="auth:invitation.form.inviteLinkDescription" /></Typography>

  </>
}


function useCourseInvitation(course: QueryDocumentSnapshot<Course>, expiresInHours = 1) {
  const firestore = useFirestore()
  const { user, roles } = useUserContext()

  const invitationsCollection = useMemo(
    () => {
      return collection(firestore, 'invitations').withConverter(typeConverter<GoSchoolInvitation>())
    }, [firestore]
  )

  const [invitationRef, setInvitationRef] = useState<DocumentReference<GoSchoolInvitation>>()
  const invitation = useFirestoreSnapshot(invitationRef)
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const createInvitation = useCallback(
    async () => {
      if (user==null) {
        throw new Error('User not authenticated')
      }
      if (course.ref.parent.parent==null) {
        throw new Error('Course not in organization')
      }
      if (invitation?.exists) {
        throw new Error('Invitation already exists')
      }
      setIsCreating(true)
      try {
        const ref = await addDoc(invitationsCollection, {
          created_at: serverTimestamp(),
          created_by: user.uid,
          roles: ['student'],
          email: null,
          course: course.ref,
          organization: course.ref.parent.parent.withConverter(typeConverter<Organization>()),
          expires_at: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * expiresInHours)
        })

        setInvitationRef(ref)
      } catch (e) {
        console.error('cannot create invitation', e)
        console.dir({roles, user})
      } finally {
        setIsCreating(false)
      }
    }, [roles, course.ref, expiresInHours, invitation?.exists, invitationsCollection, user]
  )


  return { invitation, createInvitation, isCreating }
}
