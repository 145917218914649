import { CircularProgress, Stack } from '@mui/material'
import { PageLayout } from './PageLayout'

export function LoadingPage() {
  return <PageLayout fullScreen={true}>
    <Stack gap={2} justifyContent="center" alignItems="center" flexGrow={1}>
      <CircularProgress />
    </Stack>
  </PageLayout>
}
