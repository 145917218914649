import type { CourseChat } from '@goschool/model'
import type { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore'
import { useMemo } from 'react'
import { List, ListItem, ListItemButton, ListItemText, Stack, styled } from '@mui/material'


export interface ChatListProps {
  chats: QueryDocumentSnapshot<CourseChat>[] | undefined | null;
  selectedChat: DocumentReference<CourseChat> | undefined,
  selectChat: (chat: DocumentReference<CourseChat>) => void;
}

export function CourseChatList({ chats, selectedChat, selectChat }: ChatListProps) {
  const isNewChat = useMemo(
    () => chats?.find((chat) => chat.ref.path===selectedChat?.path)==null,
    [chats, selectedChat]
  )

  if (selectedChat==null) {
    return null
  }

  return <ChatListStack gap={2}>
    <List dense={true} disablePadding={true}>
      {isNewChat &&
        <ListItem key={selectedChat.id} disableGutters={true} disablePadding={true}>
          <ListItemButton selected={true} dense={true}>
            <ChatItemText primary={'New Chat'} />
          </ListItemButton>
        </ListItem>
      }
      {
        chats?.map(
          (chat) =>
            <ListItem key={chat.id} disableGutters={true} disablePadding={true}>
              <ListItemButton selected={selectedChat?.path===chat.ref.path} dense={true}
                              onClick={() => selectChat(chat.ref)}>
                <ChatItemText primary={chat.data()?.title ?? 'new chat'} />
              </ListItemButton>
            </ListItem>)
      }
    </List>
  </ChatListStack>
}

const ChatListStack = styled(
  Stack,
  { name: 'ChatListStack', slot: 'Root' }
)(
  ({ theme }) => ({
    overflowY: 'auto'
  })
)

const ChatItemText = styled(ListItemText, { name: 'ChatItem', slot: 'Root' })(({ theme }) => ({
  '& .MuiListItemText-primary': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.body2.fontSize
  }
}))
