import { useFirebaseApp } from '@progos/firebase-chat'
import { useCallback, useMemo } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import type { DocumentReference } from 'firebase/firestore'
import type { GoSchoolInvitation } from './GoSchoolInvitation'
import type { CourseDocument } from './CourseDocument'

export function useFunctions() {
  const app = useFirebaseApp()
  const functions = useMemo(
    () => getFunctions(app, 'europe-west1'),
    [app]
  )
  const acceptInvitation = useCallback(
    async (invitationRef: DocumentReference<GoSchoolInvitation>) => {
      const accept_invitation = httpsCallable<{ id: string }, { success: boolean }>(functions, 'accept_invitation')
      const result = await accept_invitation({
        id: invitationRef.id
      })
      return result.data

    }, [functions])

  const deleteDocument = useCallback(
    async (documentRef: DocumentReference<CourseDocument>) => {
      const delete_document = httpsCallable<{ path: string }, { success: boolean }>(functions, 'delete_document')
      const result = await delete_document({
        path: documentRef.path
      })
      return result.data
    }, [functions])

  return { acceptInvitation, deleteDocument }
}
