import { colors, createTheme, darken, responsiveFontSizes } from '@mui/material'

import '@fontsource-variable/gabarito'
import '@fontsource-variable/inter'
import type { LinkProps } from '@mui/material/Link'
import { LinkBehavior } from '@goschool/mui'

const designFont = 'Gabarito Variable, sans-serif'
const bodyFont = 'Inter Variable, sans-serif'

export const goSchoolTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: darken(colors.blueGrey['900'], 0.8),
        paper: darken(colors.blueGrey['900'], 0)
      },
      primary: {
        main: colors.blue['300']
      },
      secondary: {
        main: colors.amber['A700']
      }
    },
    typography: {
      fontFamily: bodyFont,
      body1: { fontFamily: bodyFont },
      body2: { fontFamily: bodyFont, fontSize: '0.8rem' },
      h1: { fontFamily: designFont },
      h2: { fontFamily: designFont },
      h3: { fontFamily: designFont },
      h4: { fontFamily: designFont },
      h5: { fontFamily: designFont },
      h6: { fontFamily: designFont },
      subtitle1: { fontFamily: designFont },
      subtitle2: { fontFamily: designFont }
    },

    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior
        } as LinkProps
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior
        }
      }
    }
  })
)
