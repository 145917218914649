import type { CourseDocument } from '@goschool/model'
import { useEffect, useState } from 'react'
import { useFirebaseApp } from '@progos/firebase-chat'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { Document, Page, pdfjs } from 'react-pdf'
import { Pagination, Stack } from '@mui/material'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

export function PdfViewer({ document }: { document: CourseDocument | undefined }) {
  const app = useFirebaseApp()
  const [pdfUrl, setPdfUrl] = useState<string>()
  useEffect(
    () => {
      const path = document?.storage_ref
      const storage = getStorage(app)
      if (path!=null) {
        const pdfRef = ref(storage, path)
        getDownloadURL(pdfRef).then(url => {
          setPdfUrl(url)
        })
      }
    },
    [document, app]
  )

  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
  }


  return <Stack direction="column" overflow="hidden" alignItems="center" justifyContent="flex-start">
    <Pagination disabled={numPages==null} count={numPages} page={pageNumber}
                siblingCount={2} boundaryCount={2}
                onChange={(event, value) => setPageNumber(value)} />
    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} scale={1} width={800} />
    </Document>
    <Pagination disabled={numPages==null} count={numPages} page={pageNumber}
                siblingCount={1} boundaryCount={2}
                onChange={(event, value) => setPageNumber(value)} />
  </Stack>
}
